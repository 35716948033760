import { AppSettings } from '../AppSettings';
import * as AppErrors from '../Model/AppErrors';
import { ApiHelper } from '../Util/ApiHelper';
import { StringUtil } from '../Util/Helpers';
import Logger from '../Util/Logger';
import ApiResponse from '../Model/ApiResponse';
import urlJoin from 'url-join';

class AdminApiClient {
    logger;

    constructor() {
        this.logger = new Logger(AdminApiClient.name);
    }

    async adminFetch(url, req = {}) {
        try {
            const authHeaders = await ApiHelper.authHeaders();
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(req),
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });
            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.getErrorMsg(ex);
        }
    }

    async adminFetchBodyData(url, urlencoded) {
        try {
            const authHeaders = await ApiHelper.authHeaders();
            const response = await fetch(url, {
                method: "POST",
                body: urlencoded,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    ...authHeaders
                }
            });
            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.getErrorMsg(ex);
        }
    }

    async adminGet(url) {
        try {
            const authHeaders = await ApiHelper.authHeaders();
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });
            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.getErrorMsg(ex);
        }
    }

    // async adminCsvFetch(url, urlencoded) {
    //     try {
    //         const authHeaders = await ApiHelper.authHeaders();
    //         const response = await fetch(url, {
    //             method: "POST",
    //             body: urlencoded,
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded',
    //                 ...authHeaders
    //             }
    //         });

    //         let fileName = '242424242.csv';
    //         if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //         // IE variant
    //             window.navigator.msSaveOrOpenBlob(
    //                 new Blob([response.data], {
    //                 type: 'text/csv',
    //                 encoding: 'UTF-8'
    //                 }),
    //                 fileName
    //             );
    //         } else {
    //             const url = window.URL.createObjectURL(
    //                 new Blob([response.data], {
    //                 type: 'text/csv',
    //                 encoding: 'UTF-8'
    //                 })
    //             );
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', fileName);
    //             document.body.appendChild(link);
    //             link.click();
    //             link.remove();
    //         }

    //         // return response;
    //     }
    //     catch (ex) {
    //         this.getErrorMsg(ex);
    //     }
    // }

    getErrorMsg = (err) => {
        this.logger.error('admin API error', err);
        const errMsg = AppErrors.generic;
        const apiResp = new ApiResponse(true);
        apiResp.error = errMsg;
        return apiResp;
    }

    async saveRecord(req)   {
        const url = urlJoin(AppSettings.urls.policy, '/supplementary/save');
        return await this.adminFetch(url, req);
    }

    async getRecord(req){
        const url = urlJoin(AppSettings.urls.policy, '/supplementary/details');
        return await this.adminFetch(url, req);
    }

    async getSupplementarySettings(){
        const url = urlJoin(AppSettings.urls.policy, '/supplementary/getSettings');
        return await this.adminFetch(url);
    }

    async saveSupplementarySettings(req){
        const url = urlJoin(AppSettings.urls.policy, '/supplementary/saveSettings');
        return await this.adminFetch(url, req);
    }


    async supplementaryList(req) {
        const url = urlJoin(AppSettings.urls.policy, '/supplementary/list');
        return await this.adminFetch(url, req);
    }

    async listDeals(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/listDeals');
        return await this.adminFetch(url, req);
    }

    async changeTags(secureId, newTags) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/changeTags');
        const req = {
            SecureId: secureId,
            Tags: newTags
        };
        return await this.adminFetch(url, req);
    }

    async changeOwner(secureId, userInfo) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/changeOwner');
        const req = {
            SecureId: secureId,
            Owner: userInfo
        };
        return await this.adminFetch(url, req);
    }

    async changeContributors(secureId, userInfoArr) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/changeContributors');
        const req = {
            SecureId: secureId,
            Contributors: userInfoArr
        };
        return await this.adminFetch(url, req);
    }

    async loadAllTPUsers() {
        const url = urlJoin(AppSettings.urls.policy, '/admin/loadAllTPUsers');
        return await this.adminFetch(url);
    }

    async getAvailableEndorsements(applicability) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/getAvailableEndorsements');
        const req = {
            Applicability : applicability
        };
        return await this.adminFetch(url, req);
    }

    // async changeEndorsements(secureId, newEndorsements) {
    //     const url = urlJoin(AppSettings.urls.policy, '/admin/changeEndorsements');
    //     const req = {
    //         SecureId: secureId,
    //         EndorsementIds: newEndorsements
    //     };
    //     return await this.adminFetch(url, req);
    // }

    async searchLawyer(province, searchString) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/searchLawyer');
        const req = {
            Province: province,
            SearchString: searchString
        };
        return await this.adminFetch(url, req);
    }

    async listUsers(req) {
        if (StringUtil.isNullOrEmpty(req)) {
            return;
        }
        const url = urlJoin(AppSettings.urls.policy, '/admin/listUsers');
        return await this.adminFetch(url, req);
    }

    async changeUserStatus(userId, newStatus, note) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/changeUserStatus');
        const req = {
            UserSecureId: userId,
            NewStatus: newStatus,
            Note: note
        };
        return await this.adminFetch(url, req);
    }

    async getUserDetails(userId) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/getUserDetails');
        const req = {
            UserSecureId: userId            
        };
        return await this.adminFetch(url, req);
    }

    async getPinDirectories(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/listpins');
        return await this.adminFetch(url, req);
    }

    async isExistingPin(pin) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/isexistingpin');
        const req = {
            Pin: pin
        };
        return await this.adminFetch(url, req);
    }

    async getPinById(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/getPin');
        return await this.adminFetch(url, req);
    }

    async updatePin(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/updatePin');
        return await this.adminFetch(url, req);
    }

    async toggleStatus(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/togglePinStatus');
        return await this.adminFetch(url, req);
    }

    async deletePin(pinId) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/ArchivePin');
        const req = { PinId: pinId };
        return await this.adminFetch(url, req);
    }

    async getPrivateLendersList(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/listlenders');
        return await this.adminFetch(url, req);
    }

    async getPrivateLenderById(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/getLender');
        return await this.adminFetch(url, req);
    }

    async createPrivateLender(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/createLender');
        return await this.adminFetch(url, req);
    }

    async updatePrivateLender(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/updateLender');
        return await this.adminFetch(url, req);
    }

    async deletePrivateLender(lenderId) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/deleteLender');
        const req = { LenderId: lenderId };
        return await this.adminFetch(url, req);
    }

    async isExistingPrivateLender(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/isexistinglender');
        return await fetch(url, req);
    }

    async getMessagesList(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/listmessages');
        return await this.adminFetch(url, req);
    }

    async createMessage(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/createMessage');
        return await this.adminFetch(url, req);
    }

    async getMessageById(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/getMessage');
        return await this.adminFetch(url, req);
    }

    async updateMessage(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/updateMessage');
        return await this.adminFetch(url, req);
    }

    async getCurrentMessages(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/getCurrentMessages');
        return await this.adminFetch(url, req);
    }

    async deleteMessage(messageId) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/deleteMessage');
        const req = { MessageId: messageId };
        return await this.adminFetch(url, req);
    }

    async getReportsList() {
        const url = urlJoin(AppSettings.urls.policy, '/reports/getList');
        return await this.adminGet(url);
    }

    async getReport(id, startDate, endDate) {
        const url = urlJoin(AppSettings.urls.policy, '/reports/getReport');
        let urlencoded = new URLSearchParams();
        urlencoded.append("id", id);
        urlencoded.append("startDate", startDate);
        urlencoded.append("endDate", endDate);
        return await this.adminFetchBodyData(url, urlencoded);
    }

    async updateUserProfile(inputs) {
        const req = {};

        for (const fieldName in inputs) {
            const answer = inputs[fieldName];
            if (StringUtil.isEither(fieldName, "Email", "Phone")) {
                req[fieldName] = answer;
            }
        }

        const url = urlJoin(AppSettings.urls.policy, '/user/updateUserProfile');
        return await this.adminFetch(url, req);
    }

    async getAnnouncements(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/getAnnouncements');
        return await this.adminFetch(url, req);
    }
    
    async createAnnouncement(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/createAnnouncement');
        return await this.adminFetch(url, req);
    }

    async editAnnouncement(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/updateAnnouncement');
        return await this.adminFetch(url, req);
    }

    async deleteAnnouncement(id) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/deleteAnnouncement');
        const req = { AnnouncementId: id };
        return await this.adminFetch(url, req);
    }

    async getProjects(req) {
        const url = urlJoin(AppSettings.urls.policy, '/admin/getProjects');
        // console.info(`[url] - ${url}`);
        return await this.adminFetch(url, req);
    }
    
}

const instance = new AdminApiClient();

export { instance as AdminApiClient }
